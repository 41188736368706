import { cpf } from 'cpf-cnpj-validator';

/* eslint-disable no-undef */
export default (args = {}) => {
  const validateField = (fieldObject, allFields) => {
    const { element } = fieldObject;
    const field = fieldObject;

    if (element.hasAttribute('required')) {
      if (element.value === '') {
        element.setCustomValidity('Campo de preenchimento obrigatório');
        // element.reportValidity();
        element.classList.add('invalid');
        field.isValid = false;

        return false;
      }
    }

    if (element.hasAttribute('minlength')) {
      const minLength = element.getAttribute('minlength');

      if (element.value.length < minLength) {
        element.setCustomValidity('Campo inválido');
        // element.reportValidity();
        element.classList.add('invalid');
        field.isValid = false;

        return false;
      }
    }

    if (element.type === 'email') {
      const regex = new RegExp(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/, 'gi');

      if (!regex.test(element.value)) {
        element.setCustomValidity('Endereço de e-mail inválido');
        // element.reportValidity();
        element.classList.add('invalid');
        field.isValid = false;
        return false;
      }
    }

    if (element.hasAttribute('data-validation-pattern')) {
      const regex = new RegExp(element.getAttribute('data-validation-pattern'), 'gi');

      if (!regex.test(element.value)) {
        element.setCustomValidity('Campo inválido');
        // element.reportValidity();
        element.classList.add('invalid');
        field.isValid = false;
        return false;
      }
    }

    if (element.hasAttribute('data-validation-cpf')) {
      if (!cpf.isValid(element.value)) {
        element.setCustomValidity('Campo inválido');
        // element.reportValidity();
        element.classList.add('invalid');
        field.isValid = false;
        return false;
      }
    }

    if (element.hasAttribute('data-validation-match')) {
      const otherField = allFields.find(f => f.id === element.getAttribute('data-validation-match'));

      if (element.value !== otherField.element.value) {
        element.setCustomValidity('O valor deste campo deve ser igual ao anterior.');
        // element.reportValidity();
        element.classList.add('invalid');
        field.isValid = false;
        return false;
      }
    }

    if (element.type === 'radio') {
      const options = document.querySelectorAll(`[name=${element.getAttribute('name')}]`);
      const values = [];

      options.forEach(o => values.push(o.checked));

      if (values.some(v => v === true)) {
        options.forEach(o => o.classList.remove('invalid'));
        element.isValid = true;
      } else {
        options.forEach(o => o.classList.add('invalid'));
        element.isValid = false;
        return false;
      }
    }

    element.setCustomValidity('');
    element.classList.remove('invalid');
    field.isValid = true;
    return true;
  };

  if (args) {
    const fields = Object.keys(args.fields)
      .map((field) => {
        const name = field;
        const id = args.fields[field].getAttribute('id');
        const element = args.fields[field];
        const isValid = false;

        return {
          id,
          name,
          element,
          isValid,
        };
      });

    fields.forEach((field) => {
      field.element.addEventListener('change', () => validateField(field, fields));
      field.element.addEventListener('input', () => validateField(field, fields));
      field.element.addEventListener('blur', () => validateField(field, fields));
    });

    return {
      form: args.form,
      fields,
      isValid() {
        fields.forEach(field => validateField(field, fields));
        return fields.every(f => f.isValid);
      },
    };
  }

  return null;
};
