/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable no-undef */
/* eslint-disable no-console */
import api from './api';
import formApi from './form';
import validator from './validations';
import setMasks from './masks';
import debounce from './debounce';
import handleError from './handleError';
import modalAlert from './modal';
import modalLoader from './loader';
import initTooltip from './tooltip';
import getPartner from './partners';

let form = formApi.init();
let validations = validator(form);

window.onload = () => {
  // Terms & Conditions
  const termsConditionsModal = document.querySelector('.terms-conditions');
  const termsConditionsLink = document.querySelector('.terms-conditions__link');
  const termsConditionsClose = document.querySelector('.terms-conditions__close');
  const termsConditionsBody = document.querySelector('.terms-conditions__body');

  termsConditionsLink.addEventListener('click', () => {
    termsConditionsModal.classList.add('terms-conditions--open');
    termsConditionsBody.scrollTop = 0;
    document.body.style.overflow = 'hidden';
  });

  termsConditionsClose.addEventListener('click', () => {
    termsConditionsModal.classList.remove('terms-conditions--open');
    document.body.style.overflow = 'auto';
  });

  // Alert Modal
  const modalWrapper = document.querySelector('.modal');
  const modalClose = document.querySelector('.modal__close');
  const modalTitle = document.querySelector('.modal__title');
  const modalText = document.querySelector('.modal__text');
  const modal = modalAlert(modalWrapper, modalClose, modalTitle, modalText);

  const loader = modalLoader(document.querySelector('.loader'));

  const observer = new MutationObserver(() => {
    form = formApi.init();
    validations = validator(form);
  });

  observer.observe(
    document.querySelector('[data-medical-specialty]'),
    { childList: true, subtree: true },
  );

  form.form.reset();

  initTooltip();

  setMasks(
    form.fields.phone,
    ['(99) 9999-99999', '(99) 99999-9999'],
  );

  setMasks(
    form.fields.cpf,
    ['999.999.999-99'],
  );

  const createInput = () => {
    if (!(String(form.fields.medicalSpecialty.tagName).toLowerCase() === 'input')) {
      const wrapper = document.querySelector('[data-medical-specialty]');
      wrapper.querySelectorAll('select')
        .forEach((select) => {
          wrapper.removeChild(select);
        });

      const input = document.createElement('input');
      input.setAttribute('type', 'text');
      input.setAttribute('id', 'drconecta-medicalSpecialty');
      input.setAttribute('disabled', true);
      input.setAttribute('required', true);
      input.setAttribute('placeholder', 'TÍTULO DE ESPECIALISTA*');
      input.classList.add('drconecta-landing__form-field');

      wrapper.appendChild(input);
      form = formApi.init();
    }
  };

  const createSelect = () => {
    if (String(form.fields.medicalSpecialty.tagName).toLowerCase() !== 'select') {
      const wrapper = document.querySelector('[data-medical-specialty]');
      wrapper.querySelectorAll('input')
        .forEach((input) => {
          wrapper.removeChild(input);
        });

      const input = document.createElement('select');
      input.setAttribute('id', 'drconecta-medicalSpecialty');
      input.setAttribute('required', true);
      input.classList.add('drconecta-landing__form-field');
      input.style.height = '100%';

      wrapper.appendChild(input);
      form = formApi.init();
    }
  };

  const appendMedicalSpecialties = (options, select) => {
    const defaultOption = document.createElement('option');
    defaultOption.setAttribute('disabled', true);
    defaultOption.setAttribute('selected', true);
    defaultOption.setAttribute('hidden', true);
    defaultOption.setAttribute('value', '');
    defaultOption.innerText = 'TÍTULO DE ESPECIALISTA*';

    const allOptions = [defaultOption];

    options.forEach((option) => {
      const optionElement = document.createElement('option');
      optionElement.setAttribute('value', option.name);
      optionElement.innerText = option.name;

      allOptions.push(optionElement);
    });

    allOptions.forEach(o => select.appendChild(o));
  };

  const handleCRMResponse = (response) => {
    if (typeof response === 'object') {
      if ('especialidade' in response.dadosMedico) {
        form.fields.medicalSpecialty.value = response.dadosMedico.especialidade;
        form.fields.medicalSpecialty.dispatchEvent(new Event('change'));
        form.fields.isCfmValidation.value = 1;
      } else {
        createSelect();
        api.getMedicalSpecialties()
          .then(res => appendMedicalSpecialties(res, form.fields.medicalSpecialty));
        form.fields.isCfmValidation.value = 0;
      }
    } else {
      if (response === 'Api fora do ar') {
        createSelect();
        api.getMedicalSpecialties()
          .then(res => appendMedicalSpecialties(res, form.fields.medicalSpecialty));
      } else {
        modal.open(
          'Erro',
          'O CRM não é valido ou possui algum tipo de restrição. Por favor entre em contato conosco.',
        );
      }
      form.fields.isCfmValidation.value = 0;
    }
  };

  const checkCRM = debounce(() => {
    const { crm, uf, medicalSpecialty } = form.fields;

    if (crm.value !== '' && uf.value !== '') {
      loader.open();
      medicalSpecialty.value = '';
      createInput();
      api.checkCRM(crm.value, uf.value)
        .then(res => handleCRMResponse(res))
        .finally(() => loader.close());
    }
  }, 1000);

  form.fields.crm.addEventListener('input', checkCRM);
  form.fields.uf.addEventListener('change', checkCRM);

  const submit = document.querySelector('.drconecta-landing__form-submit');
  submit.addEventListener('click', () => {
    const gender = validations.fields.find(item => item.name === 'gender');
    const tc = document.querySelector('#drconecta-tc');

    if (!gender.isValid) {
      document.querySelectorAll('[name=drconecta-gender]')
        .forEach(option => option.classList.add('invalid'));
    }

    if (!tc.checked) {
      tc.classList.add('invalid');
    }
  });

  form.fields.gender.addEventListener('input', () => {
    document.querySelectorAll('[name=drconecta-gender]')
      .forEach(option => option.classList.remove('invalid'));
  });

  const tc = document.querySelector('#drconecta-tc');
  tc.addEventListener('input', () => {
    if (tc.checked) {
      tc.classList.remove('invalid');
    } else {
      tc.classList.add('invalid');
    }
  });

  const changeMask = () => {
    const mask = form.fields.ddi.value === '55'
      ? ['(99) 9999-99999', '(99) 99999-9999']
      : [form.fields.ddi.options[form.fields.ddi.selectedIndex].getAttribute('data-mask')];

    form.fields.phone.setAttribute('minlength', String(mask[0]).length);
    form.fields.phone.setAttribute('maxlength', String(mask[mask.length - 1]).length);

    setMasks(form.fields.phone, mask);
  };

  form.fields.ddi.addEventListener('change', changeMask);

  form.form.addEventListener('submit', (event) => {
    const termsAccepted = document.querySelector('#drconecta-tc');
    event.preventDefault();

    if (validations.isValid() && termsAccepted.checked) {
      const data = {
        crmRegional: form.fields.uf.value,
        crm: form.fields.crm.value,
        displayName: form.fields.displayName.value,
        hasTermsOfUse: 1,
        isCfmValidation: form.fields.isCfmValidation.value,
        medicalSpecialty: form.fields.medicalSpecialty.value,
        incomingPartnerName: getPartner,
        user: {
          cpf: form.fields.cpf.value.replace(/\D/gi, ''),
          ddiContactPhone: form.fields.ddi.value.replace(/\D/gi, ''),
          contactPhone: form.fields.phone.value.replace(/\D/gi, ''),
          email: {
            first: form.fields.emailFirst.value,
            second: form.fields.emailSecond.value,
          },
          gender: form.fields.gender.value,
          name: form.fields.name.value,
          plainPassword: {
            first: form.fields.passwordFirst.value,
            second: form.fields.passwordSecond.value,
          },
        },
      };

      const successModal = document.querySelector('.success');

      loader.open();
      api.save({ register: data })
        .then(() => {
          successModal.classList.add('modal--open');

          if (/promo.drconecta.com.br/gi.test(window.location.hostname)) {
            fbq('track', 'CompleteRegistration');
            window.location.href = `${window.location.origin}/confirmation.html`;
          }
        })
        .catch(err => modal.open('Erro', handleError(err)))
        .finally(() => loader.close());
    }
  });
};
