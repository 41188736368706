/* eslint-disable no-undef */
export default (() => {
  if (/drconecta.com.br/gi.test(window.location.hostname)) {
    const partner = String(window.location.hostname).split('.drconecta.com.br');

    return partner[0] !== 'promo'
      ? partner[0]
      : '';
  }

  return '';
})();
