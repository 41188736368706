/* eslint-disable no-console */
/* eslint-disable no-undef */
const api = () => {
  const endpoint = process.env.API;

  const capitalizeString = str => str.toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.substring(1))
    .join(' ');

  const save = (data) => {
    const url = `${endpoint}/doctor/register`;

    return new Promise((resolve, reject) => {
      fetch(url, {
        method: 'post',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json' },
      })
        .then(res => new Promise((accept) => {
          res.json()
            .then(d => accept({ status: res.status, data: d }));
        }))
        .then((res) => {
          if (parseInt(res.status, 10) >= 300) {
            reject(res.data);
          } else {
            resolve(res.data);
          }
        })
        .catch(err => reject(err));
    });
  };

  const checkIfCfmIsOnline = () => {
    const url = `${endpoint}/cfm/doctor?crm=104372&uf=SP`;

    return new Promise((resolve, reject) => {
      fetch(url, { method: 'get' })
        .then(res => res.json())
        .then(res => resolve(res))
        .catch(() => reject(new Error('Api fora do ar')));
    });
  };

  const checkCRM = (crm, uf) => {
    const url = `${endpoint}/cfm/doctor?crm=${crm}&uf=${uf}`;

    return new Promise((resolve, reject) => {
      checkIfCfmIsOnline()
        .then(() => fetch(url, { method: 'get' }))
        .then(res => res.json())
        .then(res => resolve(res))
        .catch(reject);
    });
  };

  const getMedicalSpecialties = () => {
    const url = `${endpoint}/medical_specialty?itensPerPage=all`;

    return new Promise((resolve, reject) => {
      fetch(url, { method: 'get' })
        .then(res => res.json())
        .then((res) => {
          const { data } = res;
          resolve(data.map(i => ({ id: i.id, name: capitalizeString(i.name) })));
        })
        .catch(reject);
    });
  };

  return {
    save,
    checkCRM,
    getMedicalSpecialties,
  };
};

export default api();
