/* eslint-disable no-undef */
/* eslint-disable no-param-reassign */
export default (modal, modalClose, modalTitle, modalMessage) => {
  modalClose.addEventListener('click', (event) => {
    event.preventDefault();
    modal.classList.remove('modal--open');
    document.body.style.overflow = 'auto';
  });

  const open = (title, message) => {
    modalTitle.innerText = title;
    modalMessage.innerText = message;
    modal.classList.add('modal--open');
    document.body.style.overflow = 'hidden';
  };

  return { open };
};
