/* eslint-disable no-undef */
import VMasker from 'vanilla-masker';

const masker = (element, mask) => {
  const inputHandler = (masks, max, event) => {
    const el = event.target;
    const val = el.value.replace(/\D/g, '');
    const whichMask = el.value.length > max ? 1 : 0;

    VMasker(el).unMask();
    VMasker(el).maskPattern(masks[whichMask]);
    el.value = VMasker.toPattern(val, masks[whichMask]);
  };

  VMasker(element).maskPattern(mask[0]);
  VMasker(document.querySelector('[data-mask-number]')).maskNumber();

  element.addEventListener(
    'input',
    inputHandler.bind(undefined, mask, 14),
    false,
  );
};

export default masker;
