/* eslint-disable no-undef */
const tooltip = () => {
  document.querySelectorAll('[data-tip]')
    .forEach((div) => {
      div.querySelectorAll('input')
        .forEach((input) => {
          input.addEventListener('focus', () => {
            div.classList.add('tooltipActive');
          });

          input.addEventListener('blur', () => {
            div.classList.remove('tooltipActive');
          });
        });
    });
};

export default tooltip;
