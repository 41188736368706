/* eslint-disable no-undef */
export default (loader) => {
  const open = () => {
    loader.classList.add('modal--open');
    document.body.style.overflow = 'hidden';
  };

  const close = () => {
    loader.classList.remove('modal--open');
    document.body.style.overflow = 'auto';
  };

  return {
    open,
    close,
  };
};
