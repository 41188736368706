const findErrorMessage = (error) => {
  let errorMessage = null;

  const recursiveSearch = (object) => {
    const keys = Object.keys(object);
    keys.forEach((key) => {
      if (key === 'errors') {
        errorMessage = object[key];
      } else {
        recursiveSearch(object[key]);
      }
    });
  };

  if (Array.isArray(error)) {
    error.forEach((element) => {
      if (typeof element === 'object') {
        recursiveSearch(element);
      } else {
        errorMessage = element;
      }
    });
  } else {
    recursiveSearch(error);
  }

  return errorMessage[0];
};

export default (errors) => {
  if (typeof errors !== 'object') {
    return errors;
  }

  return ('errors' in errors)
    ? findErrorMessage(errors.errors)
    : 'Ocorreu um erro.';
};
