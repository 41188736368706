/* eslint-disable no-undef */
const prefix = 'drconecta-';

const form = {
  init() {
    return {
      form: document.getElementById(`${prefix}form`),
      fields: {
        name: document.getElementById(`${prefix}name`),
        displayName: document.getElementById(`${prefix}displayName`),
        cpf: document.getElementById(`${prefix}cpf`),
        ddi: document.getElementById(`${prefix}ddi`),
        phone: document.getElementById(`${prefix}phone`),
        emailFirst: document.getElementById(`${prefix}emailFirst`),
        emailSecond: document.getElementById(`${prefix}emailSecond`),
        passwordFirst: document.getElementById(`${prefix}passwordFirst`),
        passwordSecond: document.getElementById(`${prefix}passwordSecond`),
        crm: document.getElementById(`${prefix}crm`),
        uf: document.getElementById(`${prefix}uf`),
        medicalSpecialty: document.getElementById(`${prefix}medicalSpecialty`),
        isCfmValidation: document.getElementById(`${prefix}isCfmValidation`),
        gender: document.querySelector(`[name=${prefix}gender]`),
      },
    };
  },
};

export default form;
